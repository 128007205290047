import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Select } from '../shared';

const ArchiveFarmModal = (props) => {
  const { open, onClose, onSubmit, data, mills, loading } = props;

  const { t } = useTranslation();

  const [notifiedInternalUsers, setNotifiedInternalUsers] = useState([]);
  const [notifiedExternalUsers, setNotifiedExternalUsers] = useState([]);

  useEffect(() => {
    if (open) {
      setNotifiedInternalUsers(data?.internal_users ? [...data.internal_users] : []);
      setNotifiedExternalUsers(data?.external_users ? [...data.external_users] : []);
    }
  }, [open, data]);

  const handleInternalChange = (email) => {
    if (notifiedInternalUsers?.includes(email)) {
      setNotifiedInternalUsers([...notifiedInternalUsers].filter((u) => u !== email));
    } else {
      setNotifiedInternalUsers([...notifiedInternalUsers, email]);
    }
  };

  const handleExternalChange = (email) => {
    if (notifiedExternalUsers?.includes(email)) {
      setNotifiedExternalUsers([...notifiedExternalUsers].filter((u) => u !== email));
    } else {
      setNotifiedExternalUsers([...notifiedExternalUsers, email]);
    }
  };

  return (
    <Modal
      closeText={t('cancel')}
      maxWidth='md'
      onClose={onClose}
      onSubmit={() => onSubmit([...notifiedInternalUsers, ...notifiedExternalUsers])}
      open={open}
      submitDisabled={loading}
      submitLoading={loading}
      submitText={t('archive')}
      sxBody={{ padding: '24px 16px' }}
      title={t('farmMillAssignments.archiveModalTitle')}
    >
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>{t('farmMillAssignments.millAssociation')}</Typography>
        <Box sx={{ width: '50%' }}>
          <Select
            disabled
            id='existing-mill'
            // label={t('farmMillAssignments.millAssociation')}
            name='existing-mill'
            options={mills?.map((m) => ({ key: m.mill_id, value: m.mill_id, label: m.name }))}
            value={data.mill?.mill_id}
          />
        </Box>
      </Box>
      <Grid component='div' container spacing={2}>
        <Grid component='div' item md={6} sm={12} xs={12}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>{t('farmMillAssignments.notifyInternalUsers')}</Typography>
          {data.internal_users?.map((email) => (
            <Box key={email}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifiedInternalUsers?.includes(email)}
                    onChange={(e) => handleInternalChange(email)}
                    size='small'
                  />
                }
                label={email}
              />
            </Box>
          ))}
        </Grid>
        <Grid component='div' item md={6} sm={12} xs={12}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>{t('farmMillAssignments.notifyExternalUsers')}</Typography>
          {data.external_users?.map((email) => (
            <Box key={email}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifiedExternalUsers?.includes(email)}
                    onChange={(e) => handleExternalChange(email)}
                    size='small'
                  />
                }
                label={email}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ArchiveFarmModal;
