/* eslint-disable max-len */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RationIcon from '@mui/icons-material/AssignmentOutlined';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import PrintIcon from '@mui/icons-material/Print';
import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filterCurrentMixes, getFeedsById, getMixesList, resetMixesStore } from '../../../actions/mixesActions';
import { setExactFeed } from '../../../actions/rationsActions';
import { ComparisonsGraphs, OutputsTable } from '../../../components/comparisons';
import { FeedsGraphs, FeedsInfo, FeedsTable } from '../../../components/feeds';
import LoadScreen from '../../../components/load-screen';
import { MixesTree, MixesTable } from '../../../components/mixes';
import { TemplateSelect } from '../../../components/rations';
import { SideMenu, Toggle, BottomNavigation, SearchInput, Button } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import { TabPanel, Tabs } from '../../../components/shared/tabs';
import { nutrientLabels, revertedNutrientLabels } from '../../../constants';
import { mixesUnits } from '../../../constants/enums';
import { useLoading } from '../../../hooks';
import { feedsSelector, mixesSelector, rationsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TABLE: 0,
  OUTPUTS: 1,
  GRAPHS: 2,
  TREE: 3,
};

const MixesPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const mixes = useSelector(mixesSelector);
  const feeds = useSelector(feedsSelector);
  const ration = useSelector(rationsSelector);

  const { mixList, currentMixes, isLoaded } = mixes;
  const { templateList } = feeds;

  const { exactFeed } = ration;

  const [unit, setUnit] = useState(mixesUnits.DRY_MATTER);
  const [currentInfo, setCurrentInfo] = useState(undefined);
  // const [collapsed, setCollapse] = useState(true);
  const [collapsed, setCollapse] = useState(!exactFeed && !currentMixes?.length);
  const [currentPage, setCurrentPage] = useState(!exactFeed ? tabs.TREE : tabs.TABLE);
  const [activeTab, setActiveTab] = useState(tabs.TABLE);
  const [fetching, setFetching] = useState(!!exactFeed);
  const [currentTemplate, setCurrentTemplate] = useState(undefined);
  const [search, setSearch] = useState('');
  const [pickedNutrients, setPickedNutrients] = useState([]);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const handleFeedClick = async (feed) => {
    if (currentMixes.find((q) => q.Feed_ID === feed.Feed_ID && q.OADate === feed.OADate)) {
      dispatch(
        filterCurrentMixes(currentMixes.filter((mix) => mix.Feed_ID !== feed.Feed_ID || mix.OADate !== feed.OADate))
      );
      setFetching(false);
    } else {
      try {
        setFetching(true);
        setCurrentInfo(feed);
        await dispatch(getFeedsById(feed));
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const handleRowClick = (nutr) => {
    if (pickedNutrients.find((el) => el === nutr)) {
      setPickedNutrients(pickedNutrients.filter((q) => q !== nutr));
    } else {
      setPickedNutrients([...pickedNutrients, nutr]);
    }
  };

  const handleTemplateChange = (e) => {
    setCurrentTemplate(templateList.find((el) => el.template_name === e.target.value) || undefined);
  };

  const getFeedInfo = async (mList) => {
    const filteredFeed = mList
      ?.find((q) => q.farm_id === exactFeed.farmId)
      ?.feeds?.find((fd) => fd?.feed_name === exactFeed?.feedName);

    await handleFeedClick({
      Farm_ID: exactFeed?.farmId,
      farmName: exactFeed?.farmName,
      FeedName: filteredFeed?.feed_name,
      display_formula: filteredFeed?.display_formula,
      Feed_ID: filteredFeed?.feed_id,
      OADate: filteredFeed?.OADates?.[0]?.oadate,
    });

    dispatch(setExactFeed(undefined));
  };

  const onLoad = async () => {
    try {
      startLoading();
      const mList = await dispatch(getMixesList());
      if (exactFeed) {
        await getFeedInfo(mList);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetMixesStore());
      dispatch(setExactFeed(undefined));
    };
  }, []);

  const templateColumn = useMemo(
    () =>
      currentTemplate?.ration_outputs
        ?.filter((el) => el.Visible === 'true')
        .map((el) => revertedNutrientLabels[el.Output] || el?.Output),
    [currentMixes, mixList, currentTemplate]
  );

  const getFeedColumns = () => {
    const feedRow = new Set();
    currentMixes
      ?.filter((q) => !!q.feedInfo)
      ?.forEach((q) => {
        Object?.keys(q.feedInfo)
          .filter((el) => (search ? nutrientLabels[el]?.toUpperCase()?.includes(search?.toUpperCase()) : true))
          .filter((el) => (templateColumn ? templateColumn.includes(el) : true))
          .forEach((key) => feedRow.add(key));
      });

    return Array.from(feedRow);
  };

  const getRows = () => {
    const newData = [];
    const feedColumns = getFeedColumns();

    feedColumns?.forEach((feedCol) => {
      const feedOBj = { feed_name: feedCol };
      currentMixes.forEach((q) => {
        Object.assign(feedOBj, { [`${q.OADate}`]: q?.feedInfo?.[feedCol] || 0 });
      });
      newData.push(feedOBj);
    });

    return newData;
  };

  const onClear = () => {
    dispatch(filterCurrentMixes([]));
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: { sm: '8px 16px 48px', xs: '8px 0 48px' },
  });

  const navigationTabs = [
    { value: tabs.TREE, label: t('mixes.mixesList'), icon: <TreeIcon /> },
    { value: tabs.TABLE, label: t('mixes.mixView'), icon: <RationIcon /> },
    { value: tabs.OUTPUTS, label: t('mixes.mixOutputs'), icon: <TableIcon /> },
    { value: tabs.GRAPHS, label: t('feeds.graphs'), icon: <ChartIcon /> },
  ];

  const rows = useMemo(() => getRows(), [currentMixes, mixList, currentTemplate, search]);

  return (
    <React.Fragment>
      <Container maxWidth={false} sx={{ p: { lg: 0, md: 0, sm: 0, sx: 0 }, position: 'relative' }}>
        {((loading && !isLoaded) || (mobileView && fetching)) && <LoadScreen />}
        <div className='feeds-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <MixesTree
                    currentMixes={currentMixes}
                    handleClick={handleFeedClick}
                    mixList={mixList}
                    mobileView={mobileView}
                    onClear={onClear}
                  />
                  {isLoaded && !mixList?.length && <NoResults text={t('mixes.noResults')} />}
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
                <Box component='div' sx={{ margin: '16px 0' }}>
                  <Box
                    component='div'
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
                  >
                    <h2>{t('mixes.title')}</h2>
                    <Toggle
                      handleChange={setUnit}
                      options={[
                        { label: t('asFed'), value: mixesUnits.AS_FED },
                        { label: t('dryMatter'), value: mixesUnits.DRY_MATTER },
                      ]}
                      value={unit}
                    />
                  </Box>
                  {/*   <MixesFeedList data={currentMixes} unit={unit} /> */}
                  <MixesTable data={currentMixes} isMobile={mobileView} loading={fetching} unit={unit} />
                </Box>
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.OUTPUTS)}>
                {!loading && (
                  <>
                    <Typography
                      component='h6'
                      sx={{
                        margin: '8px 0',
                        fontWeight: 'bold',
                      }}
                      variant='h6'
                    >
                      {t('mixes.mixOutputs')}
                    </Typography>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <TemplateSelect
                        currentTemplate={currentTemplate}
                        list={templateList}
                        onChange={handleTemplateChange}
                        size='small'
                      />
                    </Box>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <SearchInput
                        handleClear={() => setSearch('')}
                        onChange={(e) => setSearch(e.target.value)}
                        size='small'
                        sx={{ width: '100%' }}
                        value={search}
                      />
                    </Box>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <FeedsTable
                        data={currentMixes}
                        handleRowClick={handleRowClick}
                        isMixes={true}
                        isMobile
                        loading={fetching}
                        pickedNutrients={pickedNutrients}
                        rows={rows}
                        sx={{ maxHeight: 'calc(100vh - 170px)' }}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box component='div' sx={getMobileStyles(tabs.GRAPHS)}>
                <Box component='div' sx={{ margin: '16px 0' }}>
                  <TemplateSelect
                    currentTemplate={currentTemplate}
                    list={templateList}
                    onChange={handleTemplateChange}
                    size='small'
                  />
                </Box>
                <Box component='div' sx={{ margin: '16px 0' }}>
                  <SearchInput
                    handleClear={() => setSearch('')}
                    onChange={(e) => setSearch(e.target.value)}
                    size='small'
                    sx={{ width: '100%' }}
                    value={search}
                  />
                </Box>
                <FeedsGraphs
                  currentInfo={currentInfo}
                  currentMixes={currentMixes}
                  isMixes={true}
                  pickedNutrients={pickedNutrients}
                  rows={rows}
                  templateColumn={templateColumn}
                />
              </Box>
              <Paper elevation={3} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                <BottomNavigation
                  activeTab={currentPage}
                  onChange={(event, newValue) => {
                    if (currentInfo) {
                      setCurrentPage(newValue);
                    }
                  }}
                  tabs={navigationTabs}
                />
              </Paper>
            </>
          ) : (
            <>
              {!!mixList?.length && (
                <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                  <MixesTree
                    currentMixes={currentMixes}
                    handleClick={handleFeedClick}
                    mixList={mixList}
                    onClear={onClear}
                  />
                </SideMenu>
              )}

              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h2>{t('mixes.title')}</h2>
                  {!!currentMixes?.length && activeTab === tabs.TABLE && (
                    <Toggle
                      handleChange={setUnit}
                      options={[
                        { label: t('asFed'), value: mixesUnits.AS_FED },
                        { label: t('dryMatter'), value: mixesUnits.DRY_MATTER },
                      ]}
                      value={unit}
                    />
                  )}
                  {!!currentMixes?.length && (activeTab === tabs.OUTPUTS || activeTab === tabs.GRAPHS) && (
                    <Box
                      component='div'
                      sx={{
                        display: 'flex',
                        gap: '16px',
                      }}
                    >
                      {pickedNutrients?.length > 0 && (
                        <Button onClick={() => window.print()}>
                          <PrintIcon sx={{ mr: 1 }} />
                          {t('printCharts')}
                        </Button>
                      )}
                      <SearchInput
                        handleClear={() => setSearch('')}
                        onChange={(e) => setSearch(e.target.value)}
                        size='small'
                        value={search}
                      />
                      <TemplateSelect
                        currentTemplate={currentTemplate}
                        list={templateList}
                        onChange={handleTemplateChange}
                        size='small'
                      />
                    </Box>
                  )}
                </Box>
                {!!currentMixes?.length && (
                  <Tabs
                    activeTab={activeTab}
                    onChange={setActiveTab}
                    tabs={[
                      { label: t('mixes.title'), value: tabs.TABLE, icon: <RationIcon /> },
                      { label: t('comparisons.outputs'), value: tabs.OUTPUTS, icon: <TableIcon /> },
                      {
                        label: t('comparisons.graphs'),
                        value: tabs.GRAPHS,
                        icon: <ChartIcon />,
                        disabled: !pickedNutrients?.length,
                      },
                    ]}
                  />
                )}
                {isLoaded && !mixList?.length && <NoResults text={t('mixes.noResults')} />}
                {!currentMixes?.length && !collapsed && !exactFeed && (
                  <Box component='div' sx={{ width: 'fit-content' }}>
                    <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                      {t('mixes.warningAlert')}
                    </MuiAlert>
                  </Box>
                )}
                <TabPanel index={tabs.TABLE} value={activeTab}>
                  <MixesTable data={currentMixes} loading={fetching} unit={unit} />
                </TabPanel>
                <TabPanel index={tabs.OUTPUTS} value={activeTab}>
                  <FeedsTable
                    data={currentMixes}
                    handleRowClick={handleRowClick}
                    isMixes={true}
                    loading={fetching}
                    pickedNutrients={pickedNutrients}
                    rows={rows}
                    sx={{ maxHeight: 'calc(100vh - 309px)' }}
                  />
                </TabPanel>

                <TabPanel index={tabs.GRAPHS} value={activeTab}>
                  <FeedsGraphs
                    currentInfo={currentInfo}
                    currentMixes={currentMixes}
                    isMixes={true}
                    loading={fetching}
                    pickedNutrients={pickedNutrients}
                    rows={rows}
                    templateColumn={templateColumn}
                  />
                </TabPanel>
              </Container>
            </>
          )}
        </div>
      </Container>
      {pickedNutrients?.length > 0 && (
        <div className='print-charts'>
          <FeedsGraphs
            currentInfo={currentInfo}
            currentMixes={currentMixes}
            customKey='print'
            isAnimationActive={false}
            isMixes
            pickedNutrients={pickedNutrients}
            print
            rows={rows}
            templateColumn={templateColumn}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default MixesPage;
