import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { getFeedOutputLabel, getRationOutputLabel } from '../constants/nutrientLabels';
import { globalUnits, energyUnits, formatRatios } from '../constants/settings';
import { settingsSelector } from '../reducers/selectors';

const MINUTE_MILLISECONDS = 60 * 1000;
const DAY_MILLISECONDS = 86400000;
const MS_DAY_OFFSET = 25569;

const useSettings = () => {
  const userSettings = useSelector(settingsSelector);

  function oaDateToTicks(oaDate) {
    var ticks = (oaDate - MS_DAY_OFFSET) * DAY_MILLISECONDS;
    if (oaDate < 0) {
      const frac = (oaDate - Math.trunc(oaDate)) * DAY_MILLISECONDS;
      if (frac !== 0) {
        ticks -= frac * 2;
      }
    }
    return ticks;
  }

  const formatDate = (value, OADate = false, withTime = false) => {
    if (!value) {
      return '';
    }

    const dateFormat = userSettings.DateFormat;
    let date;
    if (OADate) {
      const ticks = oaDateToTicks(value);
      date = new Date(ticks + MINUTE_MILLISECONDS);
    } else {
      date = new Date(value);
    }

    if (withTime) {
      // const timeFormat = [dateFormats.ddMM, dateFormats.dM].includes(dateFormat) ? 'hh:mm' : 'h:mm aaa';
      const timeFormat = 'h:mm aaa';
      return date ? format(date, `${dateFormat}  ${timeFormat}`) : '';
    }

    return date ? format(date, dateFormat) : '';
  };

  // const formatLabel = (label) => {
  //   const globalUnit = userSettings.GlobalUnit;
  //
  //   if (globalUnit === globalUnits.IMPERIAL && label?.match(/[^()]*(?=\))/g)) {
  //     const unit = label?.match(/[^()]*(?=\))/g)[0];
  //     if (Object.keys(formatLabels).includes(unit)) {
  //       return label?.replace(`(${unit})`, `(${formatLabels[unit]})`);
  //     }
  //     return label;
  //   }
  //   return label;
  // };

  const formatLabel = (nutrientLabel, unit, animalType, isRation = false) => {
    const globalUnit = unit || userSettings.GlobalUnit;
    const energyUnit = userSettings.EnergyUnit;

    let label = isRation
      ? getRationOutputLabel(nutrientLabel, globalUnit, energyUnit, animalType)
      : getFeedOutputLabel(nutrientLabel, globalUnit, energyUnit, animalType);

    if (
      globalUnit === globalUnits.IMPERIAL &&
      (unit?.toLowerCase()?.includes('/100 kg') || unit?.toLowerCase()?.includes('100 kg/'))
    ) {
      label = label?.replace('100 kg', '100 lbs');
    }

    return label;
  };

  const formatValue = (value, type, withDecimal = true) => {
    if (value === null || value === undefined) {
      return '---';
    }

    const decimalFormat = userSettings.DecimalFormat;
    let decimalPrecision = 4;

    if (!type) {
      return Number(value)?.toFixed(decimalPrecision);
    }

    if (type?.toLowerCase()?.includes('forage')) {
      decimalPrecision = userSettings.ForagesDecimalPrecision;
    } else if (type?.toLowerCase()?.includes('min/vit')) {
      decimalPrecision = userSettings.MineralsDecimalPrecision;
    } else if (type?.toLowerCase()?.includes('composite')) {
      decimalPrecision = userSettings.MixesDecimalPrecision;
    } else {
      decimalPrecision = userSettings.ConcentratesDecimalPrecision;
    }

    return withDecimal
      ? value?.toFixed(decimalPrecision).replace('.', decimalFormat)
      : Number(value.toFixed(decimalPrecision));
  };

  const formatWeight = (value, type, label, withDecimal, inverted = false, initialLabel = '') => {
    const globalUnit = userSettings.GlobalUnit;
    const energyUnit = userSettings.EnergyUnit;
    let formattedValue = value;

    if (Number.isNaN(value) || value === undefined) {
      return '---';
    }

    if (energyUnit === energyUnits.MJ && label?.toLowerCase()?.includes('mj')) {
      if (inverted) {
        formattedValue = Number(formattedValue) / formatRatios.McalToMj;
      } else {
        formattedValue = Number(formattedValue) * formatRatios.McalToMj;
      }
    }

    if (globalUnit === globalUnits.IMPERIAL) {
      let ratio = 1;

      if (label && label?.toLowerCase()?.includes('(lbs)') && initialLabel.toLowerCase()?.includes('(g)')) {
        ratio = formatRatios.GtoLbs;
      } else if (label && label?.toLowerCase()?.includes('lbs/day)')) {
        ratio = formatRatios.KGtoLBS;
      } else if (label && label?.toLowerCase()?.includes('(lbs)') && initialLabel.toLowerCase()?.includes('(mg)')) {
        ratio = formatRatios.GtoLbs / 1000;
      } else if (
        label &&
        label?.toLowerCase()?.includes('(lbs)') &&
        (!initialLabel || initialLabel.toLowerCase()?.includes('(kg)'))
      ) {
        ratio = formatRatios.GtoLbs * 1000;
      } else if (label && label?.toLowerCase()?.includes('/100lb)')) {
        ratio = 1 / (formatRatios.GtoLbs * 1000);
      } else if (label && label?.toLowerCase()?.includes('/lb') && initialLabel?.toLowerCase()?.includes('/kg')) {
        ratio = 1 / (formatRatios.GtoLbs * 1000);
      } else if (label && label?.toLowerCase()?.includes('$/mt)')) {
        ratio = 1 / (formatRatios.MTtoET * 1000);
      } else if (label && label?.toLowerCase()?.includes('(kg/cu m)')) {
        ratio = formatRatios.KGpercuMtoLBSpercuFT;
      } else {
        const finalValue = Number(formatValue(formattedValue, type));
        return Number.isNaN(finalValue) ? '---' : finalValue;
      }

      if (inverted) {
        formattedValue = formattedValue / ratio;
      } else {
        formattedValue = formattedValue * ratio;
      }
    }

    return formatValue(formattedValue, type, withDecimal);
  };

  const formatCost = (value) => {
    const currencyUnit = userSettings.CurrencyUnit;
    const decimalFormat = userSettings.DecimalFormat;

    return `${currencyUnit}${value?.toFixed(2).replace('.', decimalFormat)}`;
  };

  const formatCostPerTon = (value) => {
    const currencyUnit = userSettings.CurrencyUnit;
    const decimalFormat = userSettings.DecimalFormat;
    const globalUnit = userSettings.GlobalUnit;

    const _value = globalUnit === globalUnits.IMPERIAL ? value * formatRatios.MTtoET : value;
    return `${currencyUnit}${_value?.toFixed(2).replace('.', decimalFormat)}`;
  };

  const formatDecimal = (value, digits = 2) => {
    if (Number.isNaN(value) || value === undefined) {
      return '---';
    }
    const decimalFormat = userSettings.DecimalFormat;
    return Number(value)?.toFixed(digits).replace('.', decimalFormat);
  };

  return {
    formatDate,
    formatLabel,
    formatValue,
    formatCost,
    formatCostPerTon,
    formatWeight,
    formatDecimal,
  };
};

export default useSettings;
