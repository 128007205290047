import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';

import { nutrientLabels, rationNutrientLabels } from '../../constants';
import { useSettings } from '../../hooks';
import { BarChart, LineChart, ChartContainer } from '../charts';
import { Skeleton } from '../shared';

const ComparisonsGraphs = (props) => {
  const { currentComparisons = [], selected = [], loading, print = false, customKey = '', isRation = false } = props;

  const { formatLabel, formatDate, formatWeight } = useSettings();

  const data = useMemo(() => {
    const arr = [];

    selected?.forEach((key) => {
      const result = {};
      const chartData = [];
      const field = isRation
        ? Object.keys(rationNutrientLabels)?.find((k) => rationNutrientLabels[k] === key)
        : Object.keys(nutrientLabels)?.find((k) => nutrientLabels[k] === key);
      const unit = key?.split('(')[1]?.trim()?.replace('(', '')?.replace(')', '');

      sortBy(currentComparisons, 'OADate')?.forEach((ration) => {
        if (!ration.nutrients) {
          return;
        }

        let value;

        value = ration?.nutrients[field]
          ? Number(
              Number(
                formatWeight(ration?.nutrients[field], key, formatLabel(key), undefined, false, key)
                  ?.toString()
                  .replace(',', '.')
              )?.toFixed(2)
            )
          : 0;

        chartData?.push({
          label: {
            short: formatDate(ration.OADate, true),
            full: `${ration.FarmName || ration.farmName} – ${ration.CattleName || ration.FeedName} – ${formatDate(
              ration.OADate,
              true
            )}`,
          },
          value,
        });
      });

      result.title = formatLabel(key);
      result.dataSets = [{ key: 'value' }];
      result.data = chartData;
      result.unit = unit?.includes('%') ? '%' : unit;

      arr?.push(result);
    });

    return arr;
  }, [currentComparisons, selected]);

  if (loading) {
    return <Skeleton height={500} variant='rectangular' />;
  }

  if (!data || !data.length) {
    return null;
  }
  return (
    <Box component='div'>
      <Grid component='div' container spacing={3}>
        {data?.map((item, index) => (
          <Grid
            component='div'
            item
            key={`${item.title}_${index}${customKey}`}
            md={currentComparisons?.length > 4 ? 6 : 4}
            sm={12}
            xs={12}
          >
            <ChartContainer title={`${print ? 'Comparison\n' : ''}${item.title}`}>
              {currentComparisons?.length > 9 ? (
                <LineChart data={item.data} dataSets={item.dataSets} print={print} unit={item.unit} />
              ) : (
                <BarChart data={item.data} dataSets={item.dataSets} print={print} unit={item.unit} />
              )}
            </ChartContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ComparisonsGraphs;
