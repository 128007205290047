/* eslint-disable max-len */

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { nutrientLabels } from '../../constants';
import { globalUnits } from '../../constants/settings';
import { coefficientOfVariation, getAvg, getStandardDeviation } from '../../helpers';
import { useSettings } from '../../hooks';
import { Skeleton, Table } from '../shared';

const FeedsTable = (props) => {
  const {
    data,
    rows,
    handleRowClick,
    pickedNutrients,
    loading,
    sx,
    isMobile,
    isMixes = false,
    hasMath = false,
  } = props;

  const { t } = useTranslation();

  const { formatDate, formatWeight, formatDecimal, formatLabel } = useSettings();

  const filterRow = (obj) => Object.values(obj).filter((val) => typeof val !== 'string');

  const getAdditionalCalc = (row, type) => {
    const lbl = nutrientLabels[row?.feed_name] || row?.feed_name;
    const formattedLabel = formatLabel(lbl);
    return formatWeight(type(filterRow(row)), formattedLabel, formattedLabel, undefined, false, lbl);
  };
  const displayFormulaHidden = data?.every?.(
    (mix) => typeof mix?.display_formula !== 'undefined' && !mix?.display_formula
  );

  const getColumns = () => {
    const valuesColumns = [
      {
        title: 'Nutrient',
        field: 'feed_name',
        sort: true,
        headerStyles: { paddingInlineStart: '48px' },
        formatter: (value) => (
          <Box component='div'>
            <Checkbox
              checked={!!pickedNutrients?.find((el) => el === value)}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={() => handleRowClick(value)}
              sx={{ padding: 0, marginInlineEnd: 1 }}
              value={value}
            />
            <Box component='span' sx={{ verticalAlign: 'middle' }}>
              {formatLabel(nutrientLabels[value] || value)}
            </Box>
          </Box>
        ),
        exportFormatter: (value, row) => formatLabel(row.feed_name),
      },
      ...(hasMath
        ? [
            {
              title: t('feeds.avg'),
              field: 'avg',
              formatter: (value, row) => getAdditionalCalc(row, getAvg),
            },
            {
              title: t('feeds.cv'),
              field: 'cv',
              formatter: (value, row) => getAdditionalCalc(row, coefficientOfVariation),
            },
            {
              title: t('feeds.sd'),
              field: 'sd',
              formatter: (value, row) => getAdditionalCalc(row, getStandardDeviation),
            },
          ]
        : []),
    ];
    data.forEach((el) => {
      valuesColumns.push({
        title: `${el.farmName} - ${el.FeedName}`,
        field: `${el.OADate}`,
        sort: true,
        formatter: (value, row) => {
          const label = nutrientLabels[row.label || row?.feed_name];
          const formattedLabel = formatLabel(label);
          if (typeof el?.display_formula !== 'undefined' && !el?.display_formula) {
            return '---';
          }
          return formatWeight(value, formattedLabel, formattedLabel, undefined, false, label);
        },
        headerFormatter: () => (
          <Box component='div' sx={{ minWidth: '170px' }}>
            {isMixes && (
              <>
                <Box component='div'>{el.FarmName || el.farmName}</Box>
                <Box component='div'>{el.FeedName}</Box>
              </>
            )}

            <Box component='div'>{formatDate(el.OADate, true)}</Box>
            {el?.display_formula === false && (
              <Box
                component='span'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'underline',
                }}
              >
                <VisibilityOffIcon fontSize='small' sx={{ mr: 1 }} /> {t('mixes.hiddenFormula')}
              </Box>
            )}
          </Box>
        ),
      });
    });
    return valuesColumns;
  };

  const columns = getColumns();

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  return (
    <>
      {!displayFormulaHidden && (
        <Table
          columns={columns}
          keyField='feed_name'
          pagination
          rows={rows}
          stickyColumn
          stickyHeader
          sx={sx}
          toolbar
        />
      )}
      {!rows?.length && (
        <Box
          component='h2'
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
            color: (theme) => theme?.palette?.secondary?.main,
          }}
        >
          {t(displayFormulaHidden ? 'mixes.OutputsHidden' : 'mixes.noIngr')}
        </Box>
      )}
    </>
  );
};

export default FeedsTable;
