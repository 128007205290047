/* eslint-disable max-len */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';

import { useSettings } from '../../hooks';

export default function Row(props) {
  const { row, handleChange, changes, users, handleChangeDate } = props;

  const { formatDate } = useSettings();

  const selected =
    changes.filter((el) => el.farm_id === row.farm_id && el.grant_access === true)?.length === users?.length;

  const getDate = (user) => {
    const foundDate = changes.find(
      (perm) => perm.farm_id === row.farm_id && perm?.user_id === user?.user_id
    )?.expiration_date;
    if (foundDate) {
      return formatDate(foundDate);
    }
    return '---';
  };

  const getChecked = (user) =>
    changes?.find((perm) => perm.farm_id === row.farm_id && perm?.user_id === user?.user_id)?.grant_access;
  const getCheckedDate = (user) =>
    changes?.find((perm) => perm.farm_id === row.farm_id && perm?.user_id === user?.user_id)?.expiration_date;

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell
        component='th'
        scope='row'
        sx={{
          position: 'sticky',
          outline: '1px solid #e0e0e0',
          zIndex: 2,
          left: 0,
        }}
      >
        <Checkbox
          checked={selected}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={(e) => handleChange(e.target.checked, row, 'farm', null)}
          sx={{
            padding: '0',
            marginInlineEnd: '16px',
          }}
          value={selected}
        />
        {row?.farm_name}
      </TableCell>
      {users.map((user) => (
        <TableCell align='center' key={`${row?.farm_id}_${user?.user_id}`}>
          <Chip
            avatar={
              <Checkbox
                checked={getChecked(user)}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={(e) => handleChange(e.target.checked, row, 'date', user)}
                sx={{
                  padding: '0',
                  marginInlineStart: '8px !important',
                  marginInlineEnd: '16px',
                  color: (theme) => `${theme?.palette?.primary?.main} !important`,
                }}
                value={getChecked(user)}
              />
            }
            deleteIcon={getChecked(user) ? <MoreVertIcon /> : undefined}
            label={getDate(user)}
            onDelete={getChecked(user) ? () => handleChangeDate(user, row, getCheckedDate(user)) : undefined}
            sx={{
              borderRadius: '8px',
            }}
            variant='outlined'
          />
        </TableCell>
      ))}
    </TableRow>
  );
}
