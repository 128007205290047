/* eslint-disable max-len */
import ArrowUpIcon from '@mui/icons-material/ExpandLess';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { parseInt } from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { getComparator, stableSort } from '../../../helpers/table';
import { usePrevious } from '../../../hooks';

import TableActions from './TableActions';
import TablePrint from './TablePrint';

const Table = (props) => {
  const {
    columns = [],
    rows = [],
    stickyHeader = false,
    total = false,
    stickyColumn = false,
    pagination = false,
    customFormatters = [],
    onRowClick,
    toolbar = false,
    totalStyle = {},
    rowStyles = {},
    ...other
  } = props;

  const { t } = useTranslation();

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const prevRows = usePrevious(rows);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (rows && prevRows && rows.length !== prevRows.length) {
      setPage(0);
    }
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSort = (field) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  const renderSortIcon = (field) => {
    if (orderBy === field) {
      return order === 'desc' ? <ArrowUpIcon /> : <ArrowDownIcon />;
    }
    return <UnfoldMoreIcon />;
  };

  const columnFormatter = (column, row) => {
    if (column.exportFormatter) {
      return column.exportFormatter(row[column.field], row);
    }
    return column.formatter ? column.formatter(row[column.field], row) : row[column.field];
  };

  const list = useMemo(() => {
    if (rows && rows?.length) {
      const sortedRows = stableSort(rows, getComparator(order, orderBy)) || [];
      return pagination ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;
    }
    return [];
  }, [rows, order, orderBy, page, rowsPerPage]);

  const rowsPerPageOptions = useMemo(() => {
    let options = [25];
    if (rows?.length <= 25) {
      options = [10, 15, 20, 25];
    }
    if (rows?.length > 25 && rows?.length < 50) {
      options = [25, rows?.length];
    }
    if (rows?.length >= 50 && rows?.length < 100) {
      options = [25, 50, rows?.length];
    }
    if (rows?.length >= 100) {
      options = [25, 50, 100, rows?.length];
    }
    return options;
  }, [rows.length]);

  const exportData = useMemo(() => {
    if (!columns || !rows) {
      return { columns: [], rows: [] };
    }

    const exportColumns = columns?.map((column) => ({
      label: column.title,
      key: `${column.field}`,
    }));
    const exportRows = rows?.map((item) => {
      const row = {};
      columns?.forEach((column) => {
        row[column.field] = columnFormatter(column, item);
      });
      return row;
    });
    return { columns: exportColumns, rows: exportRows };
  }, [columns, rows]);
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box sx={{ position: 'relative' }}>
      {toolbar && <TableActions columns={columns} mobileView={mobileView} rows={rows}></TableActions>}
      <TableContainer
        component={Paper}
        sx={{
          ...other.sx,
          borderRadius: pagination ? '4px 4px 0 0' : '4px',
        }}
      >
        <MuiTable aria-label='silo-table' stickyHeader={stickyHeader} sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  key={`${column.field}_${column.title}`}
                  onClick={() => {
                    if (column.sort) {
                      onSort(column.field);
                    }
                  }}
                  sx={{
                    color: '#ffffff',
                    cursor: column.sort ? 'pointer' : 'default',
                    p: '8px 16px',
                    backgroundColor: (theme) => theme?.palette?.primary?.main,
                    ...column?.headerStyles,
                    position: index === 0 && stickyColumn ? 'sticky' : 'default',
                    outline: index === 0 && stickyColumn ? '1px solid #e0e0e0' : 'none',
                    zIndex: index === 0 && stickyColumn ? 2 : 4,
                    left: 0,
                  }}
                >
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: column.align === 'right' ? 'flex-end' : 'flex-start',
                    }}
                  >
                    {column.headerFormatter ? column.headerFormatter(column.title) : column.title}
                    {!!column.sort && renderSortIcon(column.field)}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((row, idx) => (
              <TableRow
                key={`row_${idx}`}
                onClick={() => {
                  if (onRowClick) {
                    onRowClick(row, idx);
                  }
                }}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: onRowClick ? 'pointer' : 'default',
                  ...rowStyles,
                }}
              >
                {columns?.map((column, index) => (
                  <TableCell
                    key={`row_${idx}_${index}`}
                    sx={{
                      textAlign: column.align || 'left',
                      position: index === 0 && stickyColumn ? 'sticky' : 'default',
                      borderRight: index === 0 && stickyColumn ? '1px solid #e0e0e0' : 'none',
                      left: 0,
                    }}
                  >
                    {column.formatter ? column.formatter(row[column.field], row, index) : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {!!total && (
              <TableRow sx={totalStyle}>
                {columns?.map((column, colIdx) =>
                  colIdx === 0 ? (
                    <TableCell key='total'>
                      <b>Total</b>
                    </TableCell>
                  ) : (
                    <TableCell key={`cell_${colIdx}`} sx={{ textAlign: column.align || 'left' }}>
                      <b>{column.totalFormatter ? column.totalFormatter(column.field) : ''}</b>
                    </TableCell>
                  )
                )}
              </TableRow>
            )}
            {!!customFormatters?.length &&
              customFormatters?.map((customFormatter, i) => (
                <TableRow key={`tableRow_${i}`}>
                  {columns?.map((column, colIdx) =>
                    colIdx === 0 ? (
                      <TableCell key={`custom-${colIdx}`}>
                        <b>{customFormatter(column.field)?.label}</b>
                      </TableCell>
                    ) : (
                      <TableCell key={`cell-cust_${colIdx}`} sx={{ textAlign: column.align || 'left' }}>
                        {customFormatter(column.field)?.formatter}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {pagination && (
        <TablePagination
          component='div'
          count={rows.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          sx={{
            position: 'sticky',
            bottom: 0,
            background: '#ffffff',
            outline: '1px solid #e0e0e0',
            borderRadius: '0 0 4px 4px',
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        />
      )}

      <Box component='div' sx={{ display: 'none' }}>
        <TablePrint columns={columns} ref={componentRef} rows={rows} />
      </Box>
    </Box>
  );
};

export default Table;
