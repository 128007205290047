import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link';
import { sum } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getTotal } from '../../helpers/table';
import { useSettings } from '../../hooks';
import { settingsSelector } from '../../reducers/selectors';
import { Skeleton, Table } from '../shared';

const RationsTable = (props) => {
  const { data, handleFeedClick, loading, info, isMobile } = props;

  const userSettings = useSelector(settingsSelector);

  const { t } = useTranslation();
  const { formatLabel, formatCostPerTon, formatWeight } = useSettings();

  const totalFormatter = useCallback(
    (field) => {
      const result = info?.[field]
        ? formatWeight(info[field], null, '(lbs)', true)
        : getTotal(data?.map((item) => formatWeight(item[field], item?.feed_type, '(lbs)')));
      return Number(result)?.toFixed(2)?.toString()?.replace('.', userSettings.DecimalFormat);
    },
    [data, userSettings]
  );

  const columns = [
    {
      title: t('rations.feed'),
      field: 'feed_name',
      sort: true,
      formatter: (value, row) =>
        !!row?.display_formula ? (
          <Link onClick={() => handleFeedClick(value, row.feed_type)} sx={{ cursor: 'pointer' }}>
            {value} <LaunchIcon sx={{ fontSize: 14 }} />
          </Link>
        ) : (
          value
        ),
    },
    {
      title: formatLabel(`${t('rations.DM')} (kg)`),
      field: 'DM_amount',
      sort: true,
      formatter: (value, row) => formatWeight(value, row?.feed_type, '(lbs)'),
      totalFormatter,
    },
    {
      title: formatLabel(`${t('rations.asFed')} (kg)`),
      field: 'AF_amount',
      sort: true,
      formatter: (value, row) => formatWeight(value, row?.feed_type, '(lbs)'),
      totalFormatter,
    },
    {
      title: t('rations.costPerTon'),
      field: 'cost',
      sort: true,
      formatter: formatCostPerTon,
      totalFormatter: () => formatCostPerTon(info?.cost ?? sum(data?.map((item) => item.cost))),
      align: 'right',
    },
  ];

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      keyField='feed_name'
      rowStyles={{ maxHeight: '52px' }}
      rows={data}
      stickyHeader
      // stickyColumn={!!isMobile}
      sx={{ minHeight: 'calc(100vh - 231px)', maxHeight: 'calc(100vh - 231px)' }}
      total
      totalStyle={{ verticalAlign: 'bottom' }}
    />
  );
};

export default RationsTable;
