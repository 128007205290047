/* eslint-disable max-len */
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import TablePrint from './TablePrint';

const TableActions = (props) => {
  const { columns = [], rows = [], mobileView } = props;

  const { t } = useTranslation();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const columnFormatter = (column, row) => {
    if (column.exportFormatter) {
      return column.exportFormatter(row[column.field], row);
    }
    return column.formatter ? column.formatter(row[column.field], row) : row[column.field];
  };

  const exportData = useMemo(() => {
    if (!columns || !rows) {
      return { columns: [], rows: [] };
    }

    const exportColumns = columns?.map((column) => ({
      label: column.title,
      key: `${column.field}`,
    }));
    const exportRows = rows?.map((item) => {
      const row = {};
      columns?.forEach((column) => {
        row[column.field] = columnFormatter(column, item);
      });
      return row;
    });
    return { columns: exportColumns, rows: exportRows };
  }, [columns, rows]);

  const containerStyles = useMemo(() => {
    if (mobileView) {
      return { display: 'flex', justifyContent: 'flex-end' };
    }
    return { display: 'flex', position: 'absolute', top: '-26px', right: 0 };
  }, [mobileView]);

  return (
    <>
      <Box component='div' sx={containerStyles}>
        <Button onClick={handlePrint} startIcon={<PrintIcon />} sx={{ fontSize: 11, fontWeight: 'bold', mr: 1 }}>
          {t('print')}
        </Button>
        <CSVLink data={exportData?.rows} filename='export_data' headers={exportData?.columns}>
          <Button startIcon={<FileDownloadIcon />} sx={{ fontSize: 11, fontWeight: 'bold' }}>
            {t('export')}
          </Button>
        </CSVLink>
      </Box>

      <Box component='div' sx={{ display: 'none' }}>
        <TablePrint columns={columns} ref={componentRef} rows={rows} />
      </Box>
    </>
  );
};

export default TableActions;
