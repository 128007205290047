import { PanelResizeHandle } from 'react-resizable-panels';

export default function ResizeHandle({ className = '', id = 'resize-handler' }) {
  return (
    <PanelResizeHandle id={id}>
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <svg fill='none' height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'>
          <path d='M10 3L10 21' stroke='#8f8f8f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
          <path d='M14 3L14 21' stroke='#8f8f8f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
          <path d='M4 12H10' stroke='#8f8f8f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
          <path d='M14 12H20' stroke='#8f8f8f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
          <path d='M18 9L21 12L18 15' stroke='#8f8f8f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
          <path d='M6 9L3 12L6 15' stroke='#8f8f8f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
        </svg>
      </div>
    </PanelResizeHandle>
  );
}
