import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';
import { Skeleton, Table } from '../shared';

const NutrientsTable = (props) => {
  const { data, loading, animalType, mobileView } = props;

  const { t } = useTranslation();
  const { formatLabel, formatWeight, formatDecimal } = useSettings();

  const columns = [
    {
      title: t('rationOutputs'),
      field: 'label',
      sort: true,
      formatter: (value) => formatLabel(value, undefined, animalType, true),
    },
    {
      title: t('value'),
      field: 'value',
      sort: true,
      formatter: (value, row) => {
        const label = formatLabel(row.label, undefined, animalType, true);
        const _value = formatWeight(value, row.label, label, undefined, undefined, row.label);
        return isNaN(_value) ? '---' : _value;
      },
    },
  ];

  if (loading) {
    return <Skeleton rows={5} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      keyField='key'
      rows={data}
      stickyHeader
      sx={{ maxHeight: `calc(100vh - ${mobileView ? '280' : '231'}px)` }}
    />
  );
};

export default NutrientsTable;
