/* eslint-disable consistent-return */
import UserService from '../services/userService';

import { SET_MIXES_LIST, SET_MIXES_FEEDS, SET_CURRENT_MIXES, FILTER_CURRENT_MIXES } from './actionTypes';

export const setMixesList = (mixList) => ({ type: SET_MIXES_LIST, mixList });
export const setFeedsList = (feedList) => ({ type: SET_MIXES_FEEDS, feedList });
export const setCurrentMixes = (currentMixes) => ({ type: SET_CURRENT_MIXES, currentMixes });
export const filterCurrentMixes = (currentMixes) => ({ type: FILTER_CURRENT_MIXES, currentMixes });

export const getMixesList = () => async (dispatch) => {
  try {
    const res = await UserService.getMixesList();
    dispatch(setMixesList(res?.data?.mix_view_index));
    return res?.data?.mix_view_index;
  } catch (err) {
    console.error(err);
  }
};

export const getFeedsById = (obj) => async (dispatch) => {
  try {
    const res = await UserService.getFeedsById(obj);
    dispatch(setFeedsList(obj?.display_formula === false ? [] : res?.data?.mix_feeds));
    dispatch(
      setCurrentMixes({
        ...obj,
        ...(obj?.display_formula === false
          ? {}
          : { feeds: res?.data?.mix_feeds, feedInfo: res?.data?.mix_nutrients || {} }),
      })
    );
  } catch (err) {
    console.error(err);
  }
};

export const resetMixesStore = () => (dispatch) => {
  try {
    dispatch(setFeedsList([]));
    dispatch(filterCurrentMixes([]));
  } catch (err) {
    console.error(err);
  }
};
