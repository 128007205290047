import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '../shared/autocomplete';

const MillSelect = (props) => {
  const { millsList, selected, onSelect } = props;

  const { t } = useTranslation();

  const onChange = (e, value) => {
    onSelect(value);
  };

  return (
    <Box component='div' sx={{ width: { md: '50%', sm: '100%' } }}>
      <Autocomplete
        disableCloseOnSelect={false}
        getOptionLabel={(option) => option.name}
        inputLabel={t('farmMillAssignments.mill')}
        inputPlaceholder={t('farmMillAssignments.placeholder')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        labelKey='name'
        multiple={false}
        noOptionsText={t('farmMillAssignments.noOptions')}
        onChange={onChange}
        options={millsList}
        value={selected}
        valueKey='id'
      />
    </Box>
  );
};

export default MillSelect;
