/* eslint-disable max-len */

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks';
import { Table, TableChip, Skeleton } from '../shared';

const ActivityLogTable = ({ data = [], loading }) => {
  const { t } = useTranslation();
  const { formatDate } = useSettings();

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  const getActionLabel = (label) => {
    switch (label) {
      case 'upload':
        return <TableChip color='warning' icon={<CloudUploadOutlinedIcon />} label={t('upload')} />;
      case 'download':
        return <TableChip color='info' icon={<CloudDownloadOutlinedIcon />} label={t('download')} />;
      default:
        return '';
    }
  };

  const getStatusTag = (status) => {
    switch (status) {
      case 'success':
        return <TableChip color='success' icon={<DoneOutlineIcon fontSize='small' />} label={t('success')} />;
      case 'failed':
        return <TableChip color='error' icon={<ErrorOutlineOutlinedIcon fontSize='small' />} label={t('failed')} />;
      default:
        return null;
    }
  };

  const columns = [
    { title: t('activityLog.subscriberEmail'), field: 'email', sort: true },
    { title: t('activityLog.farmName'), field: 'farm_name', sort: true },
    { title: t('activityLog.farmAddress'), field: 'farm_address', sort: true },
    {
      title: t('activityLog.action'),
      field: 'action',
      sort: true,
      formatter: getActionLabel,
    },
    {
      title: t('activityLog.dateTime'),
      field: 'date_time',
      sort: true,
      formatter: (value) => (value ? formatDate(value, false, true) : '---'),
    },
    {
      title: t('activityLog.status'),
      field: 'status',
      sort: true,
      formatter: getStatusTag,
    },
    { title: t('activityLog.errors'), field: 'errors', sort: true },
  ];

  return (
    <Table
      columns={columns}
      keyField='id'
      pagination
      rows={data}
      stickyHeader
      sx={{ maxHeight: { md: 'calc(100vh - 275px)', xs: 'calc(100vh - 140px)' } }}
    />
  );
};

export default ActivityLogTable;
