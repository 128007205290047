import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = (colorMain) => {
  const themePalette = palette(colorMain);
  const config = {
    palette: themePalette,
    typography: {
      fontFamily: 'Verdana, sans-serif',
      fontWeight: 400,
      fontSize: 14,
      button: {
        fontWeight: 500,
      },
    },
    shape: {
      borderRadius: 4,
    },
    overrides: {},
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: themePalette?.primary?.greyLight,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: { backgroundColor: 'white' },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: themePalette?.primary?.main,
            // color: '#ffffff',
            '.MuiTableCell-root': {
              fontWeight: 600,
              textTransform: 'uppercase',
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: '#ffffff',
              '& > *:first-child': {
                backgroundColor: '#ffffff',
              },
            },
            '&:nth-of-type(even)': {
              backgroundColor: '#fafafa',
              '& > *:first-child': {
                backgroundColor: '#fafafa',
              },
            },
          },
          head: {
            backgroundColor: themePalette?.primary?.main,
            '& > *:first-child': {
              zIndex: 9,
              backgroundColor: `${themePalette?.primary?.main} !important`,
            },
          },
        },
      },
    },
  };

  return createTheme(config);
};

export default theme;
